<template>
  <div>
    <withdrawalList :accountType="accountType" ></withdrawalList>
  </div>
</template>

<script>
import withdrawalList from '../withdrawalList.vue'
export default {
  components: {
    withdrawalList
  },
  data () {
    return {
      accountType: 'sale'
    }
  },
  created () {
  },
  methods: {

  }
}
</script>
<style lang="scss" module>
</style>
